import Projects from "../components/Projects";

const Portfolio = () => {
  return (
    <div>
      <Projects />
    </div>
  );
};

export default Portfolio;
